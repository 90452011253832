$(document).on("change", "select[id='client_currency_code']", function () {
  const userCurrencyCode = $("#user-jurisdiction-currency-code").data("user-currency-code");
  const selectedCurrencyCode = $(this).children("option:selected").val();

  let gstType = "";
  if (selectedCurrencyCode != userCurrencyCode) {
    gstType = "ZERO";
  }

  $("input[id='client_gst_type'][type='hidden']").val(gstType);
});
