$(document).on("change", "#prior-expenses-this-year-dropdown", function (event) {
  const { value } = event.target;
  togglePriorExpensesThisYearFeedback(value);
});

document.addEventListener("change", (event) => {
  const { target } = event;
  const { id, value, dataset } = target;
  if (id === "job-category" && value) {
    showJobTypeFeedback();
  } else if (dataset.feedbackid === "document-type") {
    toggleDocumentTypeFeedback(dataset.text);
  }
});

const showJobTypeFeedback = () => {
  const feedback = document.querySelector("#job-type-feedback");
  if (feedback && feedback.classList.contains("hidden")) {
    feedback.classList.remove("hidden");
    feedback.setAttribute("aria-live", "polite");
  }
};

const toggleDocumentTypeFeedback = (selectedValue) => {
  const feedback = document.querySelector("#document-type-upload");
  if (selectedValue === "other") {
    hideElement(feedback);
    ReactRailsUJS.unmountComponents("#document-type-upload");
  } else {
    showElement(feedback);
    ReactRailsUJS.mountComponents("#document-type-upload");
  }
};

const togglePriorExpensesThisYearFeedback = (selectedValue) => {
  const feedbackIds = ["no-expenses-feedback", "accountant-feedback", "manual-feedback"];
  const feedbackIdsForDropdownValues = {
    no_expenses: "#no-expenses-feedback",
    accountant: "#accountant-feedback",
    manual: "#manual-feedback",
  };
  feedbackIds.forEach((feedbackId) => {
    const feedback = document.getElementById(feedbackId);
    hideElement(feedback);
  });

  const feedbackId = feedbackIdsForDropdownValues[selectedValue];
  const feedback = document.querySelector(feedbackId);
  showElement(feedback);
};

const validateSelfEmployedIncome = (event) => {
  const { classList } = event.target;

  const hiddenFieldValue = document.getElementById("user_income_estimate_attributes_self_employed_income").value;

  if (parseFloat(hiddenFieldValue) <= 0) {
    classList.add("is-invalid", "mb-0");
  } else {
    classList.remove("is-invalid", "mb-0");
  }
};

document.addEventListener("change", () => {
  // self-employed-income-estimate-amount
  const selfEmployedIncomeToValidate = document.querySelectorAll("#self-employed-income-amount, #self-employed-income-estimate-amount")
  // const selfEmployedIncome = document.getElementById("self-employed-income-amount");
  if (selfEmployedIncomeToValidate) {
    selfEmployedIncomeToValidate.forEach(e => {
      e.addEventListener("focusout", event => {
        validateSelfEmployedIncome(event);
      })
    })
  }
});
