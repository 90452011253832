document.addEventListener("turbolinks:load", () => {
  const modal = document.getElementById("net-self-employed-income-breakdown-modal");
  if (!modal) return;

  modal.querySelectorAll(".client-checkbox").forEach((checkbox) => {
    checkbox.addEventListener("change", (event) => {
      modal.querySelectorAll(`.pay-line-checkbox[data-client-id="${event.target.dataset.clientId}"]`).forEach((checkbox) => {
        checkbox.checked = event.target.checked;
      });

      recalculate();
    });
  });

  modal.querySelectorAll(".pay-line-checkbox").forEach((checkbox) => {
    checkbox.addEventListener("change", (event) => {
      const allChecked = [...modal.querySelectorAll(`.pay-line-checkbox[data-client-id="${event.target.dataset.clientId}"]`)].every(
        (payLineCheckbox) => payLineCheckbox.checked,
      );

      const noneChecked = [...modal.querySelectorAll(`.pay-line-checkbox[data-client-id="${event.target.dataset.clientId}"]`)].some(
        (payLineCheckbox) => !payLineCheckbox.checked,
      );

      if (allChecked) {
        modal.querySelector(`#client-${event.target.dataset.clientId}`).checked = true;
      }

      if (noneChecked) {
        modal.querySelector(`#client-${event.target.dataset.clientId}`).checked = false;
      }

      recalculate();
    });
  });

  const recalculate = () => {
    const total = [...modal.querySelectorAll(".pay-line-checkbox:checked")].reduce((sum, checkbox) => sum + Number(checkbox.value), 0);
    modal.querySelector("#total").innerHTML = `$${total.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
  };
});
