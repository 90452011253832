import DOMPurify from "dompurify";
import I18n from "../utilities/translations";

function sanitize(text: string) {
    return DOMPurify.sanitize(new DOMParser().parseFromString((text), "text/html").documentElement.textContent, { USE_PROFILES: { html: true }})
}

/**
 * On mobile, the native app is responsible for telling us whether the device has biometrics enabled via a callback function. 
 * We check for the existence of the callback, and then call it to check if biometrics are supported; if so, the biometrics section
 * on the security part of the settings page is shown. Additionally, the callback can provide us with a more specfic product name for
 * biometrics (e.g. Face ID); if the app implements this function, then we update the labels as appropriate.
 */
const updateBiometricsSettings = () => {
    if (window.BiometricsCallback) {
        const biometricsCallback = window.BiometricsCallback;
        if (biometricsCallback.isBiometricsSupported()) {
            const biometricsSettings = document.querySelector(".biometrics-settings");
            if (biometricsSettings) {
                biometricsSettings.classList.remove("soft-hidden");

                const biometricsProduct: string = biometricsCallback.getBiometricsProductName?.();
                if (biometricsProduct) {
                    const i18nContext = { scope: "users.security.biometrics" }
                    const placeholderTitle: string = I18n.t("product_title_placeholder", i18nContext);
                    const placeholderDescription: string = I18n.t("product_description_placeholder", i18nContext);

                    const heading = biometricsSettings.querySelector("#biometrics-heading");
                    if (heading) {
                        // eslint-disable-next-line xss/no-mixed-html
                        heading.innerHTML = heading.innerHTML.replace(placeholderTitle.toUpperCase(), sanitize(biometricsProduct.toUpperCase()));
                    }

                    const description = biometricsSettings.querySelector("#biometrics");
                    if (description) {
                        // eslint-disable-next-line xss/no-mixed-html
                        description.innerHTML = description.innerHTML.replace(placeholderDescription, sanitize(biometricsProduct));
                    }

                    const button = biometricsSettings.querySelector("#manage-biometrics");
                    if (button) {
                        // eslint-disable-next-line xss/no-mixed-html
                        button.innerHTML = button.innerHTML.replace(placeholderTitle, sanitize(biometricsProduct));
                    }
                }
            }
        }
    }
}

document.addEventListener("turbolinks:load", updateBiometricsSettings);